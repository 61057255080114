import axios from 'axios';

const API_BASE_URL = '/api/front';
const USER_API = '/api/user';
const NEW_API = '/api/mt';
const GROUP_API = '/api/gp';

export default {
  namespaced: true,
  state: {
    URLS: {
      LINELOGIN: `${USER_API}/lineLogin`,
      REFRESHTOKEN: `${USER_API}/refresh`,
      GETUSERDATA: `${USER_API}/me`,
      READACCOUNTINFO: `${USER_API}/accountInfo`,

      READTRANSACTIONLOG: `${API_BASE_URL}/user/transactionLog`,

      READWITHDRAWLIST: `${API_BASE_URL}/withdraw/list`,
      POSTWITHDRAWSUBMIT: `${API_BASE_URL}/withdraw/submit`,

      GETMYJOINEDLIST: `/api/join/myJoinedList`,

      GETJOURNEYDETAILBYPATH: `/api/getJourneyDetailByPath`,

      READPATHDAYTEMPLATES: `/api/getPathDayTemplates`,
      READJOURNEYDETAILBYTEMPLATE: `/api/getJourneyDetailByTemplate`,

      READUSERJOINRECORDLIST: '/api/join/list',

      // 使用者相關
      getUserMe: `/api/user/me`,

      // Journey 行程相關
      getJourneyList: `${NEW_API}/journey/list`,
      getJourneyDetail: `${NEW_API}/journey/detail`,
      postJourneyCreate: `${NEW_API}/journey/create`, //暫時停用, 建立行程請用組團建立
      postJourneyDelete: `${NEW_API}/journey/delete`,
      getJourneyGPX: `${NEW_API}/journey/gpx`, //直連網址下載
      getJourneyPassRule: `${NEW_API}/journey/passRule`,
      postJourneyUpdate: `${NEW_API}/journey/update`,

      // Path 官方路線相關
      getPathOrigin: `${NEW_API}/path/origin`, //無行程
      getPathDetail: `${NEW_API}/path/detail`,
      getPathJourneyFormat: `${NEW_API}/path/journeyFormat`,
      getPathDayTemplate: `${NEW_API}/path/dayTemplate`,
      getPathSearch: `${NEW_API}/path/search`,
      getPathDrawingConfig: `${NEW_API}/path/drawingConfig`,
      getPathFavorite: `${NEW_API}/path/favorite`,
      postPathFavorite: `${NEW_API}/path/favorite`,
      getPathDayTemplateJourneyFormat: `${NEW_API}/path/dayTemplateDetail`,

      // group 組團相關
      postGroupCreate: `${GROUP_API}/group/create`,
      postGroupSetJoin: `${GROUP_API}/group/setJoin`,
      getGroupList: `${GROUP_API}/group/list`,
      getGroupDetail: `${GROUP_API}/group/detail`,
      postGroupDelete: `${GROUP_API}/group/delete`,
      getGroupOwn: `${GROUP_API}/group/own`,
      getGroupMembers: `${GROUP_API}/group/member`,
      postGroupJoin: `${GROUP_API}/group/join`,
      postGroupUpdateJoin: `${GROUP_API}/group/updateJoin`,
      postGroupLeave: `${GROUP_API}/group/leave`,
      postGroupRemove: `${GROUP_API}/group/remove`,
      postGroupTestIsJoined: `${GROUP_API}/group/testIsJoined`,
      getCountryList: `${GROUP_API}/group/countyList`,
      getCityList: `${GROUP_API}/group/cityList`,
      postGroupDuplicate: `${GROUP_API}/group/duplicate`,
    }
  },
  getters: {
    axiosConfig(state, getters, rootState) {
      return {
        headers: {
          'token': rootState.token,
          'Cache-Control': 'no-cache',
        }
      };
    },
  },
  mutations: {

  },
  actions: {
    postApiPromise({getters}, {url, data}) {
      return new Promise(function(resolve, reject) {
        const postData = data? data: {};
        axios.post(url, postData, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    postApiNewPromise({getters}, {url, params}) {
      return new Promise(function(resolve, reject) {
        axios.post(url, params, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    // getApiPromise({getters}, {url}) {
    //   return new Promise(function(resolve, reject) {
    //     axios.get(url, getters.axiosConfig).then(function(d) {
    //       if (d.status === 200 && d.data.status === 'ok') {
    //         resolve(d.data.data);
    //       } else {
    //         reject(d.data.reason);
    //       }
    //     }).catch(function(e) {
    //       reject(e);
    //     });
    //   });
    // },
    getApiPromise({ getters }, { url, params }) {
      return new Promise(function(resolve, reject) {
        axios.get(url, {
          ...getters.axiosConfig,
          params: params, // 將 params 參數傳遞給 Axios 的 get 方法
        }).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    postLineLoginPromise({dispatch, state}, {code, callbackUri}) {
      return dispatch('postApiPromise', {
        url: state.URLS.LINELOGIN,
        data: {code, callbackUri}
      });
    },
    postRefreshToken({dispatch, state}, token) {
      return dispatch('postApiPromise', {
        url: state.URLS.REFRESHTOKEN,
        data: {token}
      });
    },
    getUserDataPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETUSERDATA,
      });
    },
    getAccountInfoPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.READACCOUNTINFO,
      });
    },
    getTransactionLogPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.READTRANSACTIONLOG,
      });
    },
    getWithdrawListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.READWITHDRAWLIST,
      });
    },
    postWithdrawSubmitPromise({dispatch, state}, {amount, bankCode, bankAccount, saveAccount}) {
      return dispatch('postApiPromise', {
        url: state.URLS.POSTWITHDRAWSUBMIT,
        data: {amount, bankCode, bankAccount, saveAccount}
      });
    },
    getMyJoinedListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GETMYJOINEDLIST,
      });
    },
    getJourneyDetailByPathPromise({ dispatch, state }, serial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.getPathDetail}/${serial}`,
      });
    },
    readPathDayTemplatesPromise({ dispatch, state }, serial) {
      return dispatch('getApiPromise', {
        url: state.URLS.READPATHDAYTEMPLATES,
        params: {
          serial: serial
        }
      });
    },
    readJourneyDetailByTemplatePromise({ dispatch, state }, serial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.getPathDayTemplateJourneyFormat}/${serial}`,
      });
    },
    getJourneyJoinSubmitListPromise({ dispatch, state }, journeyId) {
      return dispatch('getApiPromise', {
        url: state.URLS.READUSERJOINRECORDLIST + '?id=' + journeyId,
      });
    },
    getCountryListPromise({ dispatch, state }) {
      return dispatch('getApiPromise', {
        url: state.URLS.getCountryList,
      });
    },
    getCityListPromise({ dispatch, state }, countyId) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.getCityList}/${countyId}`,
      });
    },
    // 以上為舊版api, 將逐步汰換
    // --------------------------------------------------------------------------------------------
    // 以下為新版api
    // 使用者相關
    getUserMePromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getUserMe,
      });
    },


    // Journey 行程相關
    getJourneyListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getJourneyList,
      });
    },
    getJourneyDetailPromise({ dispatch, state }, id) {
      return dispatch('getApiPromise', {
        url: state.URLS.getJourneyDetail + '/' + id,
      });
    },
    postJourneyCreatePromise({ dispatch, state }, {tempId, startDate}) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postJourneyCreate,
        params: {
          tempId: tempId,
          startDate: startDate,
        }
      });
    },
    postJourneyDeletePromise({ dispatch, state }, id) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postJourneyDelete,
        params: {
          id: id,
        }
      });
    },
    getJourneyGPXPromise({ dispatch, state }, id) {
      return dispatch('getApiPromise', {
        url: state.URLS.getJourneyGPX + '/' + id,
      });
    },
    getJourneyPassRulePromise({ dispatch, state }, id) {
      return dispatch('getApiPromise', {
        url: state.URLS.getJourneyPassRule + '/' + id,
      });
    },
    postJourneyUpdatePromise({ dispatch, state }, { serial, startDate, endDate, durationDays, climbRate, journeySplitIndexs, journey }) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postJourneyUpdate,
        params: {
          serial: serial,
          startDate: startDate,
          endDate: endDate,
          durationDays: durationDays,
          climbRate: climbRate,
          journeySplitIndexs: journeySplitIndexs,
          journey: journey,
        }
      });
    },

    // Path 官方路線相關
    getPathOriginPromise({ dispatch, state }, serial) {
      return dispatch('getApiPromise', {
        url: state.URLS.getPathOrigin + '/' + serial,
      });
    },
    getPathDetailPromise({ dispatch, state }, serial) {
      return dispatch('getApiPromise', {
        url: state.URLS.getPathDetail + '/' + serial,
      });
    },
    getPathJourneyFormatPromise({ dispatch, state }, serial) {
      return dispatch('getApiPromise', {
        url: state.URLS.getPathJourneyFormat + '/' + serial,
      });
    },
    getPathDayTemplatePromise({ dispatch, state }, serial) {
      return dispatch('getApiPromise', {
        url: state.URLS.getPathDayTemplate + '/' + serial,
      });
    },
    getPathSearchPromise({dispatch, state}, query) {
      return dispatch('getApiPromise', {
        url: state.URLS.getPathSearch,
        params: {
          query: query,
        }
      });
    },
    getPathDrawingConfigPromise({ dispatch, state }, serial) {
      return dispatch('getApiPromise', {
        url: state.URLS.getPathDrawingConfig + '/' + serial,
      });
    },
    getPathFavoritePromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getPathFavorite,
      });
    },
    postPathFavoritePromise({ dispatch, state }, {path_id, favorite_boolean}) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postPathFavorite,
        params: {
          path_id: path_id,
          favorite: favorite_boolean,
        }
      });
    },

    //組團相關
    postGroupCreatePromise({ dispatch, state }, {tempId, startDate}) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postGroupCreate,
        params: {
          tempId: tempId,
          startDate: startDate,
        }
      });
    },
    postGroupSetJoinPromise({ dispatch, state }, {groupSerial, joinFlow, joinLimit}) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postGroupSetJoin,
        params: {
          groupSerial: groupSerial,
          joinFlow: joinFlow,
          joinLimit: joinLimit,
        }
      });
    },
    getGroupListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getGroupList,
      });
    },
    getGroupDetailPromise({ dispatch, state }, id) {
      return dispatch('getApiPromise', {
        url: state.URLS.getGroupDetail + '/' + id,
      });
    },
    postGroupDeletePromise({ dispatch, state }, id) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postGroupDelete,
        params: {
          id: id,
        }
      });
    },
    getGroupOwnPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.getGroupOwn,
      });
    },
    getGroupMembersPromise({ dispatch, state }, groupSerial) {
      return dispatch('getApiPromise', {
        url: state.URLS.getGroupMembers + '/' + groupSerial,
      });
    },
    postGroupJoinPromise({ dispatch, state }, { groupSerial, lineId, realName, phone, cityCode, address, fullAddress, personalId, birthday, emergencyName, emergencyPhone, email }) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postGroupJoin,
        params: {
          groupSerial: groupSerial,
          lineId: lineId,
          realName: realName,
          phone: phone,
          cityCode: cityCode,
          address: address,
          fullAddress: fullAddress,
          personalId: personalId,
          birthday: birthday,
          emergencyName: emergencyName,
          emergencyPhone: emergencyPhone,
          email: email,
        }
      });
    },
    postGroupUpdateJoinPromise({ dispatch, state }, { groupSerial, lineId, realName, phone, cityCode, address, fullAddress, personalId, birthday, emergencyName, emergencyPhone, email }) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postGroupUpdateJoin,
        params: {
          groupSerial: groupSerial,
          lineId: lineId,
          realName: realName,
          phone: phone,
          cityCode: cityCode,
          address: address,
          fullAddress: fullAddress,
          personalId: personalId,
          birthday: birthday,
          emergencyName: emergencyName,
          emergencyPhone: emergencyPhone,
          email: email,
        }
      });
    },
    postGroupLeavePromise({ dispatch, state }, groupSerial) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postGroupLeave,
        params: {
          groupSerial: groupSerial,
        }
      });
    },
    postGroupRemovePromise({ dispatch, state }, { groupSerial, memberSerial }) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postGroupRemove,
        params: {
          groupSerial: groupSerial,
          memberSerial: memberSerial,
        }
      });
    },
    postGroupTestIsJoinedPromise({ dispatch, state }, groupSerial) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postGroupTestIsJoined,
        params: {
          groupSerial: groupSerial,
        }
      });
    },
    postGroupDuplicatePromise({ dispatch, state }, groupId) {
      return dispatch('postApiNewPromise', {
        url: state.URLS.postGroupDuplicate,
        params: {
          groupId: groupId,
        }
      });
    },

  },

}
