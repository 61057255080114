import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
  },
  {
    path: '/terms',
    name: 'Term',
    component: () => import(/* webpackChunkName: "term" */ '../views/Terms.vue')
  },
  {
    // 路線搜尋結果
    path: '/searchResult',
    name: 'SearchResult',
    component: () => import(/* webpackChunkName: "searchResult" */ '../views/search/SearchResult.vue')
  },
  {
    // 資料庫範圍
    path: '/db',
    name: 'DatabaseRange',
    component: () => import(/* webpackChunkName: "databaseRange" */ '../views/DatabaseRange.vue')
  },
  {
    // 我的行程
    path: '/plan',
    name: 'MyPlan',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "myPlan" */ '../views/plan/MyPlanNew.vue'),
  },
  {
    // 路線詳細頁
    path: '/path/:id',
    name: 'PathDetail',
    component: () => import(/* webpackChunkName: "pathDetail" */ '../views/path/PathDetail.vue'),
  },
  {
    // 路線建立行程頁
    path: '/path/:id/createJourney',
    name: 'PathCreateJourney',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "pathCreateJourney" */ '../views/path/PathCreateJourney.vue'),
  },
  {
    // 行程詳細內容
    path: '/journey/:id',
    name: 'JourneyDetail',
    meta: {
    },
    component: () => import(/* webpackChunkName: "journeyDetail" */ '../views/journey/JourneyDetail.vue'),
    // children: [
    //   {
    //     // 行程詳細內容 - 路線分頁
    //     path: 'route',
    //     name: 'JourneyDetailRoute',
    //     component: () => import(/* webpackChunkName: "journeyDetailRoute" */ '../views/journey/JourneyDetailRoute.vue'),
    //   },
    //   {
    //     // 行程詳細內容 - 接駁分頁
    //     path: 'transmission',
    //     name: 'JourneyDetailTransmission',
    //     component: () => import(/* webpackChunkName: "journeyDetailTransmission" */ '../views/journey/JourneyDetailTransmission.vue'),
    //   },
    //   {
    //     // 行程詳細內容 - 管理
    //     path: 'manage',
    //     name: 'JourneyDetailManage',
    //     component: () => import(/* webpackChunkName: "journeyDetailManage" */ '../views/journey/JourneyDetailManage.vue'),
    //   },
    // ],
  },
  {
    // 行程詳細內容 - 人員分頁
    path: '/journey/:id/member',
    name: 'JourneyDetailMember',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "journeyDetailMember" */ '../views/journey/JourneyDetailMember.vue'),
  },
  {
    // 行程詳細內容 - 申請分頁
    path: '/journey/:id/submit',
    name: 'JourneyDetailSubmit',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "journeyDetailSubmit" */ '../views/journey/JourneyDetailSubmit.vue'),
  },
  {
    // 行程詳細內容 - 編輯
    path: '/journey/:id/edit',
    name: 'JourneyDetailEdit',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "journeyDetailEdit" */ '../views/journey/JourneyDetailEdit.vue'),
  },
  {
    // 組團詳細內容
    path: '/group/:id',
    name: 'GroupDetail',
    meta: {
    },
    component: () => import(/* webpackChunkName: "GroupDetail" */ '../views/group/GroupDetail.vue'),
  },
  {
    // 組團詳細內容 - 人員分頁
    path: '/group/:id/join',
    name: 'GroupDetailMemberJoin',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "GroupDetailMemberJoin" */ '../views/group/GroupDetailMemberJoin.vue'),
  },
  {
    // 組團詳細內容 - 申請分頁
    path: '/group/:id/submit',
    name: 'GroupDetailSubmit',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "GroupDetailSubmit" */ '../views/group/GroupDetailSubmit.vue'),
  },
  {
    // 組團詳細內容 - 人員分頁
    path: '/group/:id/member',
    name: 'GroupDetailMember',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "GroupDetailMember" */ '../views/group/GroupDetailMemberEdit.vue'),
  },
  {
    // 組團詳細內容 - 編輯
    path: '/group/:id/edit',
    name: 'GroupDetailEdit',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "GroupDetailEdit" */ '../views/group/GroupDetailEdit.vue'),
  },
  {
    // 組團詳細內容 - 計劃書
    path: '/group/:id/plan',
    name: 'GroupPlanBook',
    meta: {
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "GroupPlanBook" */ '../views/group/GroupPlanBook.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// 跳頁前檢查用戶是否已登入, 測試中...
router.beforeEach(async(to, from, next) => {
  const isAuthenticated = store.getters.hasToken;

  if (!isAuthenticated && to.matched.some(record => record.meta.requiresAuth)) {
    // 沒有 token，且在需要資料驗證的頁面時將目標路由保存到 Vuex 及導向登入頁
    store.commit('setRedirectRoute', to.fullPath);
    next('/login');
  } else {
    // 有 token 或者不需要資料驗證的頁面，繼續執行 ensureUser
    await store.dispatch('ensureUser');
    next();
  }
});

export default router
