<template>
  <div ref="nav">
    <nav class="navbar justify-content-between align-items-center fixed-top custom-z-index">
      <RouterLink :to="{name: 'Index' }" class="navbar-brand" id="nav-link-home">
        <img class="brand-logo-txt" :src="require('@/assets/images/svgs/logo-text.svg')" alt="brand-logo-text">
      </RouterLink>
      <div class="search-tool-btn d-flex d-md-none justify-content-center align-items-center" @click="searchToggle()" v-if="navSearchbarShow">
        <Icon class="icon" name="search" size="24" />
      </div>
      <div :class="['nav-search-bar' ,'d-none', navSearchbarShow ? 'd-md-block' : '' ]">
        <Icon class="icon" name="search" size="20" />
        <input class="fs-body-16" type="text" placeholder="搜尋路線或山岳名稱" v-model="searchText" @keydown.enter="searchClicked" @click="inputClicked">
      </div>
      <div class="nav-link-group d-flex justify-content-end align-items-center">
        <RouterLink :to="{name: 'MyPlan'}" class="nav-link fs-body-16" href="/my_trip_plan" id="nav-link-my-trip">
          <span>我的計畫</span>
        </RouterLink>
        <div class="nav-link account fs-body-16 cursor-pointer position-relative" v-if="user" @click="sidebarToggle()">
          <div class="avatar circle-nav">
            <img :src="user.pic_url" alt="avatar">
          </div>
          <div :class="['login-dropdown', sidebarActive ? 'active' : '']">
            <RouterLink class="login-dropdown-item d-flex align-items-center flex-nowrap fs-body-16" :to="{name: 'Account'}" id="nav-link-line-official">
<!--              <span>{{user.name}}</span>-->
              <span>我的帳戶</span>
            </RouterLink>
            <a class="login-dropdown-item d-flex align-items-center flex-nowrap fs-body-16" href="https://lin.ee/0kSLswL" id="nav-link-line-official">
              <span>Line @官方帳號</span>
            </a>
            <div class="login-dropdown-item d-flex align-items-center flex-nowrap fs-title-16" @click.stop="logout">
              <span>登出</span>
            </div>
          </div>
        </div>
        <div class="nav-link account fs-body-16 cursor-pointer" v-if="!user" @click="navLogin">
          <div class="avatar circle-nav d-md-none">
            <Icon name="account_circle" size="40" />
          </div>
          <span class="d-none d-md-block">註冊 / 登入</span>
        </div>
      </div>
    </nav>
    <div :class="[sidebarActive ? 'active' : '']" class="sidebar common-transition d-flex flex-column align-items-center d-md-none custom-z-index" ref="sidebar">
      <div class="sidebar-user d-flex flex-column align-items-center" v-if="user">
        <div class="avatar circle-side">
          <img v-if="user" :src="user.pic_url" alt="avatar">
          <Icon v-else name="account_circle" size="74" />
        </div>
        <div class="user-name fs-title-14">{{user.name}}</div>
        <div class="link-group">
          <RouterLink class="d-flex align-items-center fs-body-16" :to="{name: 'Account'}" id="nav-link-mobile-account">
            <Icon name="account_circle" size="24" />
            <span>我的帳戶</span>
          </RouterLink>
          <RouterLink class="d-flex align-items-center fs-body-16" :to="{name: 'MyPlan'}" id="nav-link-mobile-line-official">
            <Icon name="mountains" size="24" />
            <span>我的計畫</span>
          </RouterLink>
          <a class="d-flex align-items-center fs-body-16" href="https://lin.ee/0kSLswL" id="nav-link-mobile-line-official">
            <Icon name="line" size="24" />
            <span>Line @揪山登山路線查詢小幫手</span>
          </a>
<!--          <RouterLink class="d-flex align-items-center fs-body-16" :to="{name: 'Term'}" id="nav-link-mobile-term">-->
<!--            <Icon name="list" size="24" />-->
<!--            <span>使用者條款與隱私權</span>-->
<!--          </RouterLink>-->
          <a class="d-flex align-items-center fs-title-16" href="#" @click.stop="logout">
            <span>登出</span>
          </a>
        </div>
      </div>
      <div class="siderbar-toggle-btn" @click="sidebarToggle()">
        <Icon name="close" size="24" />
      </div>
    </div>
    <div :class="[searchActive ? 'active' : '']" class="search-tool common-transition d-flex flex-column d-md-none">
      <div class="search-nav">
        <img :src="require('@/assets/images/icon/arrow_back.svg')" alt="返回頁面" @click="searchToggle()">
        <input class="fs-body-16" type="text" placeholder="搜尋路線或山岳名稱" v-model="searchText" @keydown.enter="searchClicked" @click="inputClicked">
      </div>
      <div class="search-suggestion d-flex flex-column">
        <div class="d-none">
          <div class="title fs-body-14">熱門搜尋</div>
          <div class="btn-group d-flex flex-wrap">
            <span class="fs-body-16 d-none">尚無熱門搜尋</span>
            <div class="btn search-btn" @click="searchTarget($event)">嘉明湖</div>
          </div>
          <div class="title fs-body-14">搜尋紀錄</div>
          <div class="btn-group d-flex flex-wrap">
            <span class="fs-body-16 d-none">尚無搜尋紀錄</span>
            <div class="btn search-btn" @click="searchTarget($event)">嘉明湖</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { RouterLink } from 'vue-router';


export default {
  name: 'Index',
  data() {
    return {
      searchText: '',
      sidebarActive: false,
      searchActive:  false,
    };
  },
  props: {
    navSearchbarShow: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  components: {
    RouterLink
},
  computed: {
    user() {
      return this.$store.getters.userData;
    }
	},
  watch: {
    
  },
  mounted() {
    
	},
  methods: {
    searchClicked(event) {
      console.log(event);
      if(event.isComposing) {
        return;
      }
      if (this.searchText.length === 0) {
        alert('請至少輸入一個字');
      } else {
        // window.trackCustomEvent('NavSearch', this.searchText);
        const currentRoute = this.$router.currentRoute;
        console.log(currentRoute.name)
        if(currentRoute.name === 'SearchResult') {
          this.$router.push({ name: 'SearchResult', query: { query: this.searchText } }).catch(() => {});
        } else {
          this.$router.push({ name: 'SearchResult', query: { query: this.searchText, } });
        }
        this.searchActive = false;
      }
    },
    sidebarToggle() {
      // $(this.$refs['sidebar']).toggleClass('active');
      if(this.sidebarActive === false) {
        this.show()
      }  else {
        this.hide()
      }
      this.sidebarActive = !this.sidebarActive;
      // window.trackCustomEvent('Clicked', 'nav-toggle');
    },
    navLogin() {
      // window.trackCustomEvent('Clicked', 'nav-login');
      // window.liffLogin();
      this.$router.push({name: 'Login'});
    },
    logout() {
      const currentRoute = this.$router.currentRoute;
      // window.trackCustomEvent('Clicked', 'nav-logout');
      // window.liff.logout();
      this.$store.dispatch('logout');
      this.sidebarToggle();

      // 登出時的導航
      if (currentRoute.name !== 'Index') {
        this.$router.push({name: 'Index'}); // 只有在不是首頁時才進行導航
      }
    },
    show () {
      document.addEventListener('click', this.hidePanel, false)
    },
    hide () {
      document.removeEventListener('click', this.hidePanel, false)
    },
    hidePanel (e) {
      if (!this.$refs.nav.contains(e.target)) {
        this.sidebarActive = false
        this.hide()
      }
    },
    inputClicked() {
      // window.trackCustomEvent('Clicked', 'nav-search-input');
    },
    searchToggle() {
      this.searchActive = !this.searchActive
    },
    searchTarget(e) {
      const currentRoute = this.$router.currentRoute;
      // window.location.href = '/search_result?query=' + e.target.innerText;
      console.log(currentRoute.path)
      this.$router.push({ name: 'SearchResult', query: { query: e.target.innerText } });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
  .nav-link.account {
    cursor: pointer;
  }
  .avatar.circle-nav {
    background: none;
    border: none;
    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
